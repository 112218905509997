<template lang="pug">
h2.mt-8.text-2xl.font-semibold.mb-2 Geolocations
.border.rounded-md.mb-4(v-if="geolocations && geolocations.length > 0")
  table.table-auto.w-full.text-left
    thead.border-b
      tr
        th.w-8
        th Date
        th Phone
        th Status
    tbody
      tr(v-for="geo in geolocations", :key="geo._id")
        td
          .indicator(:class="{ succeeded: geo.state == 'succeeded' }")
        td {{ dateTrasnform(geo.created_at) }}
        td.text-xs {{ geo.phone_number }}
        td.text-xs {{ geo.state }}
.flex.w-full.mt-4(v-else)
  .bg-gray-100.border-2.border-gray-400.p-2.w-full.rounded-md
    .text-xs.text-yellow-600.font-semibold.pl-2 This user not uses geolocations
</template>
<script>
import { dateTransformerClassic } from "@/composables/dateTransformer";

export default {
  name: "boxGeolocations",
  props: {
    geolocations: null,
  },
  methods: {
    dateTrasnform(date) {
      const d = dateTransformerClassic(date);
      return d.dateTransformed;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>