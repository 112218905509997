<template lang="pug">
div(v-if="listPayments && listPayments.length > 0")
  h2.mt-8.text-2xl.font-semibold.mb-2 List Payments
  .border.rounded-md.mb-4
    table.table-auto.w-full.text-left
      thead.border-b
        tr
          th.w-8
          th Date
          th Type
          th Status
          th Amount
          th
      tbody
        tr(v-for="payment in listPayments", :key="payment._id")
          td
            .indicator(
              :class="payment.chargerback_id ? 'chargeback' : payment.payment_status"
            )
          td {{ dateTrasnform(payment.created_at) }}
          td.text-xs {{ payment.payment_type }}
          td.text-xs {{ payment.chargerback_id ? 'Chargeback' : payment.payment_status }}
          td {{ cashTransform(payment.payment_amount) }}
          td.flex.items-end.justify-end.pr-2.gap-2
            baseButton(
              size="lite",
              color="red",
              :disabled="payment.payment_status != 'succeeded' || !!payment.chargerback_id",
              v-on:clickButton="openModalRefund(payment._id)"
            ) Refund
            baseButton(
              size="lite",
              color="yellow",
              :disabled="payment.payment_status != 'succeeded' || !!payment.chargerback_id",
              v-on:clickButton="openModalChargerback(payment._id)"
            ) Chargerback

baseModal(:show="showModalRefund", v-on:close="closeModalRefund")
  baseModalCard(
    headerTitle="Refund Payment",
    contentMessage="Please, confirm this action.",
    v-on:clickDecline="closeModalRefund",
    v-on:clickConfirm="refundPayment"
  )
baseModal(:show="showModalChargerback", v-on:close="closeModalChargerback")
  baseModalCard(
    headerTitle="Mark as chargerback Payment",
    contentMessage="Please, confirm this action.",
    v-on:clickDecline="closeModalChargerback",
    v-on:clickConfirm="chargerbackPayment"
  )
baseAlert(ref="alertBox")
</template>

<script>
import cashTransformer from "@/composables/cashTransformer";
import { dateTransformerClassic } from "@/composables/dateTransformer";
import paymentsRepository from "@/api/payments.repository";

export default {
  name: "boxPayments",
  props: {
    listPayments: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    paymentToRefundId: null,
    showModalRefund: false,
    paymentToChargerbackId: null,
    showModalChargerback: false,
  }),
  inject: ["reloadUserData"],
  methods: {
    cashTransform(cash) {
      return cashTransformer(cash);
    },
    dateTrasnform(date) {
      const d = dateTransformerClassic(date);
      return d.dateTransformed;
    },
    openModalRefund(payment_id) {
      this.paymentToRefundId = payment_id;
      this.showModalRefund = true;
    },
    closeModalRefund() {
      this.paymentToRefundId = null;
      this.showModalRefund = false;
    },
    async refundPayment() {
      const { data } = await paymentsRepository.refundPayment(
        this.paymentToRefundId
      );
      if (data.isRefunded) {
        this.$refs.alertBox.showAlert("Payment Refunded", "success");
      } else {
        this.$refs.alertBox.showAlert("Error: " + data.errorCode, "error");
      }
      this.closeModalRefund();
      this.reloadUserData();
    },

    openModalChargerback(payment_id) {
      this.paymentToChargerbackId = payment_id;
      this.showModalChargerback = true;
    },

    closeModalChargerback() {
      this.paymentToChargerbackId = null;
      this.showModalChargerback = false;
    },

    async chargerbackPayment() {
      await paymentsRepository.chargerbackPayment(this.paymentToChargerbackId);
      this.closeModalChargerback();
      this.reloadUserData();
    },
  },
};
</script>
<style lang="scss" scoped>
table tbody tr td {
  @apply py-2;
}
</style>