<template lang="pug">
.grid.grid-cols-2.gap-4(v-if="user")
  baseInputText(labelText="Email", :disabled="true", v-model="user.user_email")
  baseInputText(
    labelText="Password",
    :disabled="true",
    v-model="user.v_password"
  )
  baseInputText(labelText="IP", :disabled="true", v-model="user.user_ip")
  baseInputText(
    labelText="Country/Language",
    :disabled="true",
    v-model="countryLang"
  )
  baseInputText(
    labelText="User created at",
    :disabled="true",
    v-model="dateTransformedRef"
  )
</template>
<script>
import { toRefs, watch, ref } from "vue";
import dateTransformer from "@/composables/dateTransformer";

export default {
  name: "boxUserEntity",
  props: {
    user: Object,
  },
  setup(props) {
    const { user } = toRefs(props);
    const dateTransformedRef = ref(null);
    const transformDate = () => {
      const { dateTransformed } = dateTransformer(user.value.created_at);
      dateTransformedRef.value = dateTransformed.value;
    };
    watch(user, transformDate);
    return {
      dateTransformedRef,
    };
  },
  computed: {
    countryLang() {
      return `${this.user.user_country}/${this.user.user_language}`;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>