<template lang="pug">
.container
  baseHeading User Profile
  .text-sm.-mt-4 user id: <b>{{ user_id }}</b>
  .pill.text-xs.bg-green-500.inline-block.px-2.py-1.rounded-full.mt-2(
    v-if="typeUser == 'subscriptor'"
  ) Subscriptor
  .pill.text-xs.bg-yellow-500.inline-block.px-2.py-1.rounded-full.mt-2(
    v-if="typeUser == 'premium_access'"
  ) Premium access
  .grid.grid-cols-2.mt-4.gap-5
    div
      boxUserEntity(:user="user")
      boxUserBilling(:userDataBilling="userDataBilling")
      boxGeolocations(:geolocations="geolocations")
    .mb-8
      boxUserProfilePayments(
        :userProfilePayments="userProfilePayments",
        :isActive="user?.user_active"
      )
      boxPaymentMethods(:paymentMethods="orders")
  boxListPayments(:listPayments="payments")
</template>
<script>
import UsersRepository from "@/api/users.repository";

import boxUserEntity from "@/components/users/boxUserEntity";
import boxUserBilling from "@/components/users/boxUserBilling";
import boxUserProfilePayments from "@/components/users/boxUserProfilePayments";
import boxPaymentMethods from "@/components/users/boxPaymentMethods";
import boxListPayments from "@/components/users/boxPayments";
import boxGeolocations from "@/components/users/boxGeolocations";

import { computed, provide, ref, toRefs } from "vue";

export default {
  name: "ProfileUser",
  components: {
    boxUserEntity,
    boxUserBilling,
    boxUserProfilePayments,
    boxPaymentMethods,
    boxListPayments,
    boxGeolocations,
  },
  props: {
    user_id: String,
  },
  setup(props) {
    const { user_id } = toRefs(props);
    const user = ref(null);
    const userProfilePayments = ref(null);
    const userDataBilling = ref(null);
    const orders = ref(null);
    const payments = ref(null);
    const geolocations = ref(null);

    const loadUserData = () => {
      UsersRepository.getUserProfile(user_id.value).then(({ data }) => {
        console.log(data);
        user.value = data.user;
        userProfilePayments.value = data.userProfilePayment;
        userDataBilling.value = data.userDataBilling;
        orders.value = data.orders;
        payments.value = data.payments;
        geolocations.value = data.geolocations;
      });
    };

    const typeSubscriptionUser = computed(() => {
      let subscription = "";
      if (
        user.value?.user_active &&
        userProfilePayments.value.next_payment_at
      ) {
        subscription = "subscriptor";
      } else if (
        user.value?.user_active &&
        !userProfilePayments.value.next_payment_at
      ) {
        subscription = "premium_access";
      }
      return subscription;
    });

    provide("reloadUserData", loadUserData);

    loadUserData();

    return {
      user,
      userProfilePayments,
      userDataBilling,
      orders,
      payments,
      geolocations,
      typeUser: typeSubscriptionUser,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>