<template lang="pug">
h2.mt-8.text-2xl.font-semibold User data
.grid.grid-cols-2.gap-4(v-if="userDataBilling")
  baseInputText(
    labelText="Name",
    :disabled="true",
    v-model="userDataBilling.user_name"
  )
  baseInputText(
    labelText="Last name",
    :disabled="true",
    v-model="userDataBilling.user_last_name"
  )
  baseInputText(
    labelText="Address",
    :disabled="true",
    v-model="userDataBilling.address"
  )
  baseInputText(
    labelText="City",
    :disabled="true",
    v-model="userDataBilling.city"
  )
  baseInputText(
    labelText="Postal Code",
    :disabled="true",
    v-model="userDataBilling.postal_code"
  )
  baseInputText(
    labelText="Identification Nº",
    :disabled="true",
    v-model="userDataBilling.user_identification_number"
  )
.flex.w-full.mt-4(v-else)
  .bg-gray-100.border-2.border-gray-400.p-2.w-full.rounded-md
    .text-xs.text-yellow-600.font-semibold.pl-2 This user not complete information
</template>
<script>
export default {
  name: "boxUserBilling",
  props: {
    userDataBilling: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
</style>