<template lang="pug">
.block(v-if="userProfilePayments")
  .border-b.border-gray-600
    .flex.items-center.justify-between.mb-4(
      v-if="userProfilePayments?.premium_ends_at"
    )
      .text-base Has premium access up to:
      .text-lg.font-medium {{ transformDate(userProfilePayments.premium_ends_at) }}

    .flex.items-center.justify-between.mb-4(
      v-if="userProfilePayments.cancelled_at"
    )
      .text-base Has cancel premium on:
      .text-lg.font-medium {{ transformDate(userProfilePayments.cancelled_at) }}

    .flex.items-center.justify-between.mb-4(
      v-if="userProfilePayments?.cancelled_type"
    )
      .text-base Cancel type:
      .text-lg.font-medium {{ userProfilePayments.cancelled_type }}
    .flex.items-center.justify-between.mb-4(
      v-if="userProfilePayments?.next_payment_at"
    )
      .text-base Next payment day:
      .text-lg.font-medium {{ transformDate(userProfilePayments.next_payment_at) }}
  .border-b.border-gray-600(v-if="isActive")
    .flex.justify-end.mt-4.mb-4
      baseButton.bg-red-500(@click="showCancelComplete = true") Cancel Complete
      baseButton.bg-yellow-500.ml-4(@click="showCancelNormal = true") Cancel Normal
baseModal(:show="showCancelComplete", v-on:close="showCancelComplete = false")
  .default-card.w-full.max-w-lg.bg-gray-700.rounded-2xl.pb-8
    .font-bold.text-xl.mb-1.border-b.border-gray-600.pb-2.mb-2.text-gray-200 Cancel account complete?
    .text-base.border-b.border-gray-600.pb-2.mb-2 Please, confirm this action.
    .flex.justify-end
      baseButton.bg-green-500(@click="showCancelComplete = false") NO
      baseButton.bg-red-500.ml-2(@click="cancelAccount('complete')") YES
baseModal(:show="showCancelNormal", v-on:close="showCancelNormal = false")
  .default-card.w-full.max-w-lg.bg-gray-700.rounded-2xl.pb-8
    .font-bold.text-xl.mb-1.border-b.border-gray-600.pb-2.mb-2.text-gray-200 Cancel account normal?
    .text-base.border-b.border-gray-600.pb-2.mb-2 Please, confirm this action.
    .flex.justify-end
      baseButton.bg-green-500(@click="showCancelNormal = false") NO
      baseButton.bg-red-500.ml-2(@click="cancelAccount('default')") YES
</template>
<script>
import UsersRepository from "@/api/users.repository";
import { dateTransformerClassic } from "@/composables/dateTransformer";
export default {
  name: "boxUserProfilePayments",
  inject: ["reloadUserData"],
  props: {
    userProfilePayments: Object,
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showCancelComplete: false,
    showCancelNormal: false,
  }),
  methods: {
    transformDate(date) {
      const p = dateTransformerClassic(date);
      return p.dateTransformed;
    },
    async cancelAccount(type) {
      await UsersRepository.cancelSubscription(
        this.userProfilePayments.user_id,
        type
      );
      this.showCancelComplete = false;
      this.showCancelNormal = false;
      this.reloadUserData();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>