<template lang="pug">
h2.text-2xl.font-semibold.mb-2 Payment Methods
.bg-gray-700.rounded-xl.py-2.px-4.border-2.border-gray-900.mt-2.shadow-lg(
  v-for="paymentMethod in paymentMethods",
  :key="paymentMethod._id"
)
  div(v-if="paymentMethod.pan")
    .flex.justify-between.items-center
      .cc-brand.font-semibold {{ paymentMethod.card_brand }}
        span.text-xs.pl-1.font-light ({{ paymentMethod.card_type }})
      .cc-country {{ paymentMethod.cardICountryISO3 }}

    .flex.justify-between.items-center
      .cc-card {{ paymentMethod.pan }}
      .cc-expiry-date {{ paymentMethod.expiry_date }}

    .flex.justify-between.items-center
      .cc-psd2 {{ paymentMethod.psd2_card }}
      .cc-created-at {{ transformerDate(paymentMethod.created_at) }}
  .flex(v-else)
    .text-base Payment method not completed
</template>
<script>
import { dateTransformerClassic } from "@/composables/dateTransformer";
export default {
  name: "boxPaymentMethods",
  props: {
    paymentMethods: {
      type: Array,
      default: null,
    },
  },
  methods: {
    transformerDate(date) {
      const d = dateTransformerClassic(date);
      return d.dateTransformed;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>